<template>
  <el-dialog :close-on-click-modal="false" v-model="dialogVisible" custom-class="padding-dialog"
    :title="`${comInfo.name} 【${comInfo.type == '1' ? '小规模纳税人' : '一般纳税人'}】 - AI智能记账配置`" width="855px">
    <el-scrollbar :maxHeight="contentStyleObj" v-loading="loading" style="padding-right: 6px;">
      <div v-if="!hintStr" class="hint_btn">
        <el-button size="small" type="primary" @click="setHy" plain>设置行业</el-button>
      </div>
      <div v-if="hintStr" class="hint">
        <div class="hint_title">专属AI记账模型已生成</div>
        <div class="hint_content">
          <el-icon>
            <CircleCheck />
          </el-icon><span>
            {{ hintStr }}&nbsp;<span class="hint_set" @click="setHy">设置</span>
          </span>
        </div>
      </div>
      <div v-if="comInfo.type == '2'">
        <el-alert type="warning" :closable="false">
          <template #title>
            <i class="iconfont icon-guangbo"></i> 本公司是一般纳税人，月初系统默认会把全部进项发票自动勾选认证
          </template>
        </el-alert>
      </div>
      <div v-if="tempList && tempList.length > 0" class="temp_style">
        <!-- <span v-for="item in tempList" :key="item.id" @click="changeTemp(item)" class="temp_each">{{ item.name }}
          <el-icon @click.stop.prevent="delTemplate(item)" class="del_icon">
            <CloseBold />
          </el-icon>
        </span> -->
        <el-select v-model="selectTempId" clearable style="width: 108px;margin-top: 5px;" placeholder="选择模板录入"
          popper-class="temp_style_select_option_item" size="small" @change="(val) => changeTemp(val)">
          <el-option v-for="item in tempList" :key="item.id" :label="item.name" :value="item.id">
            <div>
              <span>{{ item.name }}</span>
              <el-icon v-if="item.id != selectTempId" @click.stop.prevent="() => delTemplate(item)">
                <Delete />
              </el-icon>
            </div>

          </el-option>
        </el-select>
      </div>
      <el-form :model="form" label-width="130px" size="small" :inline="true" class="mt-5">
        <el-tabs v-model="activeTabs" type="border-card">
          <el-tab-pane name="1" label="记账基础配置">
            <div v-if="!hintStr">
              <div class="record">公司类型</div>
              <el-form-item label="公司业务类型:">
                <select-common v-model:modelValue="form.ywlx" :options="ywlxOptions" class="ml-mr-0 w-120"
                  @change="changeZdzg" />
              </el-form-item>
            </div>
            <div class="record">基本设置</div>
            <el-form-item label="申报类型:">
              <select-common v-model:modelValue="form.sblx" :options="sblxOptions" class="ml-mr-0 w-120"
                @change="changeSblx" />
            </el-form-item>
            <el-form-item label="自动勾选采集发票:" v-if="form.sblx == 1">
              <select-common v-model:modelValue="form.gxcj" :options="gxOptions" class="ml-mr-0 w-120" />
            </el-form-item>
            <div>
              <div class="record">数据确认配置</div>
              <el-form-item label="发票数据确认方式:">
                <select-common v-model:modelValue="form.cjqr" :options="qrOptions" class="ml-mr-0 w-120"
                  :disabled="qrStatus" />
              </el-form-item>
              <el-form-item label="银行流水确认方式:">
                <select-common v-model:modelValue="form.bankqr" :options="qrOptions" class="ml-mr-0 w-120" />
              </el-form-item>
              <div>
                <el-form-item label="人员工资确认方式:">
                  <select-common v-model:modelValue="form.salaryqr" :options="qrOptions" class="ml-mr-0 w-120" />
                </el-form-item>
                <!-- <el-form-item label="质检确认方式:">
              <select-common v-model:modelValue="form.zjqr" :options="qrOptions" class="ml-mr-0 w-120" />
            </el-form-item> -->
              </div>
              <el-divider border-style="dashed" />
              <el-form-item>
                <template #label>
                  <img src="../../../assets/sbj.png" class="sj_style">税款确认方式:
                </template>
                <select-common v-model:modelValue="form.skqr" :options="qrOptions" class="ml-mr-0 w-120" />
              </el-form-item>
              <el-form-item label="自动税款确认限额:" v-if="!form.skqr">
                <el-input v-model="form.skqrfz" class="w-120"></el-input>（元）
                <el-tooltip content="（*单税款金额低于此金额，系统自动确认。）" placement="top">
                  <i class="iconfont icon-wenhao"></i>
                </el-tooltip>
              </el-form-item>
              <div>
                <el-form-item>
                  <template #label>
                    <img src="../../../assets/sbj.png" class="sj_style">社保确认方式:
                  </template>
                  <select-common v-model:modelValue="form.shebaoqr" :options="qrOptions" class="ml-mr-0 w-120" />
                </el-form-item>
              </div>
              <div>
                <div class="record">账务标准配置</div>
                <el-form-item label="发票采集区间:">
                  <el-select v-model="form.jdcj" class="ml-mr-0 w-120">
                    <el-option label="单月采集" :value="0"></el-option>
                    <el-option label="季度采集" :value="1" v-if="comInfo.type == '1'"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="银行是否采集:">
                  <el-select v-model="form.yh" class="ml-mr-0 w-120">
                    <el-option label="采集" :value="0"></el-option>
                    <el-option label="不采集" :value="1"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="银行采集区间:" v-if="!form.yh">
                  <el-select v-model="form.jdcjBank" class="ml-mr-0 w-120">
                    <el-option label="单月采集" :value="0"></el-option>
                    <el-option label="季度采集" :value="1" v-if="comInfo.type == '1'"></el-option>
                  </el-select>
                </el-form-item>
                <el-tabs v-model="activeName" type="border-card">
                  <el-tab-pane name="1" label="发票配置">
                    <el-form-item label="进项往来科目:">
                      <el-select v-model="form.jxjsfs" class="ml-mr-0 w-120">
                        <el-option label="应付账款" :value="0"></el-option>
                        <el-option label="预付账款" :value="1"></el-option>
                        <el-option label="其他应付款" :value="2"></el-option>
                        <el-option label="现金结算" :value="3"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="进项小额结算阈值:">
                      <el-input v-model="form.xjjsfz" class="w-120" placeholder="低于多少元直接结算"></el-input>
                    </el-form-item>
                    <el-form-item label="进项小额结算科目:">
                      <el-select v-model="form.xjjskm" class="ml-mr-0 w-120">
                        <el-option label="现金" :value="0"></el-option>
                        <el-option label="其他应付款-小额支出" :value="1"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="销项往来科目:">
                      <el-select v-model="form.xxjsfs" class="ml-mr-0 w-120">
                        <el-option label="应收账款" :value="0"></el-option>
                        <el-option label="预收账款" :value="1"></el-option>
                        <el-option label="其他应收款" :value="2"></el-option>
                        <el-option label="现金结算" :value="3"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="销项小额结算阈值:">
                      <el-input v-model="form.xjjsfzOut" class="w-120" placeholder="低于多少元直接结算"></el-input>
                    </el-form-item>
                    <el-form-item label="销项小额结算科目:">
                      <el-select v-model="form.xjjskmOut" class="ml-mr-0 w-120">
                        <el-option label="现金" :value="0"></el-option>
                        <el-option label="其他应收款-小额收入" :value="1"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-tab-pane>
                  <el-tab-pane name="2" label="银行配置">
                    <el-form-item label="进项对冲科目:">
                      <el-select v-model="form.jxyhjsfs" class="ml-mr-0 w-120">
                        <el-option label="应付账款" :value="0"></el-option>
                        <el-option label="预付账款" :value="1"></el-option>
                        <el-option label="其他应付款" :value="2"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="销项对冲科目:">
                      <el-select v-model="form.xxyhjsfs" class="ml-mr-0 w-120">
                        <el-option label="应收账款" :value="0"></el-option>
                        <el-option label="预收账款" :value="1"></el-option>
                        <el-option label="其他应收款" :value="2"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="往来结算科目:">
                      <el-select v-model="form.bankGr" class="ml-mr-0 w-200">
                        <el-option label="其他应收/应付账款-个人姓名" :value="0"></el-option>
                        <el-option label="其他应收/应付账款-个人" :value="1"></el-option>
                      </el-select>
                      <span class="bank_hint">例如：{{ form.bankGr === 0 ? '其他应收款-张三' : '其他应收款-个人' }}</span>
                    </el-form-item>
                  </el-tab-pane>
                  <el-tab-pane name="3" label="存货配置">
                    <el-alert :title="`说明：库存商品“苹果”入库将会生成格式：“${chTitle}”的会计科目`" type="success" :closable="false" />
                    <div class="stock">
                      <el-radio-group v-model="form.chbc">
                        <el-radio :label="0">{{ filterText(form.ywlx) }}-商品分类<span
                            style="color: red">（推荐）</span></el-radio>
                        <el-radio :label="1">{{ filterText(form.ywlx) }}-商品名称</el-radio>
                        <el-radio :label="2">{{ filterText(form.ywlx) }}-商品分类-商品名称
                          （复杂）</el-radio>
                        <el-radio :label="3">{{ filterText(form.ywlx, "1") }}（简单）</el-radio>
                        <el-radio :label="4">{{
                          filterText(form.ywlx)
                        }}（商品分类辅助核算）</el-radio>
                        <div>
                          <el-radio :label="5" style="margin-right: 8px;">{{ filterText(form.ywlx)
                            }}-（商品名称辅助核算）</el-radio>
                          <el-radio :label="6">{{ filterText(form.ywlx) }}（极简）</el-radio>
                        </div>
                      </el-radio-group>
                    </div>
                    <!-- <div class="stock_hint">
                警告：选择“库存商品-商品名称（辅助核算）/库存商品（极简）”规则，需要保证一级科目“库存商品”余额为0
              </div> -->
                  </el-tab-pane>
                  <el-tab-pane name="4" label="成本核算配置">
                    <div v-if="form.ywlx == 2">
                      <el-form-item label="领料加工核算:">
                        <el-select class="ml-mr-0 w-200" v-model="lljghs">
                          <el-option label="按“成本比例”核算" :value="0"></el-option>
                        </el-select>
                      </el-form-item>
                      <!-- <el-form-item label="设定成本占比:">
                        <el-input v-model="form.scmllTemp" class="w-120" placeholder="例如：以销售额90%为成本"></el-input>&nbsp;%
                        <span class="ml-5 yczt">*0 默认不做“成本核算”</span>
                      </el-form-item> -->
                      <el-form-item label="设定成本占比(小数):">
                        <el-input v-model="form.scmll" class="w-120" placeholder="例如：0.9（以销售额90%为成本）"></el-input>
                        <span class="ml-5 yczt">*0 默认不做“成本核算”</span>
                      </el-form-item>
                    </div>
                    <el-form-item :label="form.ywlx == 3 ? '工程核算方式:' : '贸易核算方式:'">
                      <el-select v-model="form.hslx" class="ml-mr-0 w-200">
                        <el-option label="按照“实际进销存”核算（进销一致）" :value="1"></el-option>
                        <el-option label="按“成本比例”核算（进销不一致）" :value="0"></el-option>
                      </el-select>
                    </el-form-item>
                    <!-- <el-form-item label="设定成本占比:" v-if="form.hslx == 0">
                      <el-input v-model="form.myhsBlTemp" class="w-120" @change="changeZdzg"
                        placeholder="例如：以销售额90%为成本"></el-input>&nbsp;%
                      <span class="ml-5 yczt">*0 默认不做“成本核算”</span>
                    </el-form-item> -->
                    <el-form-item label="设定成本占比(小数):" v-if="form.hslx == 0">
                      <el-input v-model="form.myhsBl" class="w-120" placeholder="例如：0.9（以销售额90%为成本）"></el-input>
                      <span class="ml-5 yczt">*0 默认不做“成本核算”</span>
                    </el-form-item>
                    <el-form-item label="存货暂估方式:" v-if="form.hslx == 1">
                      <el-select v-model="form.zdzgsz" class="ml-mr-0 w-120">
                        <el-option label="自动暂估" :value="1"></el-option>
                        <el-option label="不暂估" :value="0"></el-option>
                      </el-select>
                    </el-form-item>
                    <div v-if="form.hslx == 1">
                      <el-form-item label="单价比例手动设置:">
                        <el-select v-model="form.djblsz" class="ml-mr-0 w-200">
                          <el-option label="手动设置" :value="0"></el-option>
                          <el-option label="不手动设置" :value="1"></el-option>
                        </el-select>
                      </el-form-item>
                      <!-- <el-form-item label="单价成本比例:" v-if="form.djblsz == 0">
                        <el-input v-model="form.djblTemp" class="w-120"> </el-input>&nbsp;%
                      </el-form-item> -->
                      <el-form-item label="单价成本比例(小数):" v-if="form.djblsz == 0">
                        <el-input v-model="form.djbl" class="w-120"> </el-input>
                      </el-form-item>
                    </div>
                    <el-form-item label="成本费用暂估方式:">
                      <el-select v-model="form.fyzdzg" class="ml-mr-0 w-200">
                        <el-option label="自动按利润率暂估" :value="2"></el-option>
                        <el-option label="自动按成本比例暂估" :value="1"></el-option>
                        <el-option label="不暂估" :value="0"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="暂估类型:" v-if="form.fyzdzg">
                      <el-select v-model="form.fycb" class="ml-mr-0 w-120">
                        <el-option label="成本" :value="0"></el-option>
                        <el-option label="费用" :value="1"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item :label="form.fyzdzg == 2 ? '利润率(小数):' : '暂估比例(小数):'" v-if="form.fyzdzg">
                      <el-input v-model="form.zgbl" class="w-200" placeholder="例如：0.9（占利润总额的90%）"></el-input>
                    </el-form-item>
                    <!-- <el-form-item :label="form.fyzdzg == 2 ? '利润率:' : '暂估比例:'" v-if="form.fyzdzg">
                      <el-input v-model="form.zgblTemp" class="w-200" placeholder="例如：占利润总额的90%"></el-input>&nbsp;%
                    </el-form-item> -->
                  </el-tab-pane>
                </el-tabs>
              </div>
            </div>
            <!-- <el-form-item label="特殊行业:">
              <select-common v-model:modelValue="form.tshy" :options="tshyOptions" class="ml-mr-0 w-120" />
            </el-form-item> -->
          </el-tab-pane>
          <el-tab-pane name="2" label="记账辅助配置">
            <div class="record">自动调整配置</div>

            <el-form-item label="资金赤字自动调整:" label-width="160px">
              <el-select v-model="form.zj_not_zero" class="ml-mr-0 w-120">
                <el-option label="自动调整" :value="0"></el-option>
                <el-option label="不自动调整" :value="1"></el-option>
              </el-select>
            </el-form-item>
            <!-- <el-form-item label="费用超标自动调整:" label-width="160px">
              <el-select v-model="form.cbjc" class="ml-mr-0 w-120">
                <el-option label="自动调整" :value="0"></el-option>
                <el-option label="不自动调整" :value="1"></el-option>
              </el-select>
            </el-form-item> -->
            <div class="record">费用票据配置</div>
            <el-form-item label="复制薪资比例区间:" label-width="160px">
              <el-input v-model="form.salarySmall" class="w-120"> </el-input>&nbsp;%&nbsp;-&nbsp;<el-input
                v-model="form.salaryBig" class="w-120"> </el-input>&nbsp;%
            </el-form-item>
            <div>
              <el-form-item label="无票收入自动录入:" label-width="160px">
                <el-select v-model="form.wpsrStatus" class="ml-mr-0 w-120">
                  <el-option label="不自动录入" :value="0"></el-option>
                  <el-option label="自动录入" :value="1"></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div v-if="form.wpsrStatus == 1">
              <el-form-item label="录入周期:" label-width="160px">
                <el-select v-model="form.wpsrZq" class="ml-mr-0 w-120">
                  <el-option label="按月" :value="0"></el-option>
                  <el-option label="按季" :value="1"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="金额区间:" label-width="90px">
                <el-input v-model="form.wpsrSmall" class="w-120"> </el-input>&nbsp;-&nbsp;<el-input
                  v-model="form.wpsrBig" class="w-120"> </el-input>
              </el-form-item>
            </div>
            <!-- <el-form-item label="录入费用区间:" label-width="160px">
              <el-input v-model="form.fySmall" class="w-120"> </el-input>&nbsp;-&nbsp;<el-input v-model="form.fyBig"
                class="w-120"> </el-input>
            </el-form-item> -->
          </el-tab-pane>
        </el-tabs>
      </el-form>
    </el-scrollbar>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="addTemplate" :disabled="tempList?.length > 4" type="primary" size="small">保存为模板</el-button>
        <el-button size="small" @click="closeDialogAndClearData">取消</el-button>
        <el-button size="small" type="primary" @click="save">确定</el-button>
      </span>
    </template>
  </el-dialog>
  <setSshy ref="setSshyRef" @success="getInfo" />
</template>

<script setup>
import { ref, onMounted, getCurrentInstance, computed } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { Delete } from '@element-plus/icons-vue'
import { companyAutomaticSetList, companyAutomaticSet, eaCompanyAutomaticSettingTempCre, eaCompanyAutomaticSettingTempDel, eaCompanyAutomaticSettingTempList } from "@/api/automatic"
import { SPECIAL_INDUSTRY_OPTION } from '@/utils/commonData'
import setSshy from './setSshy.vue'
const { proxy } = getCurrentInstance()
const props = defineProps({
  idKey: {
    type: String,
    default: 'comId'
  }
})
const typeMap = {
  2: '原材料',
  3: "工程物资"
}
const chTitle = computed(() => {
  const chbcMap = {
    0: '-水果',
    1: '-苹果',
    2: '-水果-苹果',
    3: '-' + (typeMap[form.value.ywlx] || '库存商品'),
    4: '-水果（苹果）',
    5: '（苹果）',
    6: ''
  }
  let text = typeMap[form.value.ywlx] || '库存商品'
  return text + (chbcMap[form.value.chbc]);
})
const emit = defineEmits()
const form = ref({})
const comInfo = ref({})
const loading = ref(false)
const hintStr = ref('')
const contentStyleObj = ref({})
const lljghs = ref(0)
const tshyOptions = SPECIAL_INDUSTRY_OPTION
const setSshyRef = ref()
const selectTempId = ref()
const sblxOptions = [
  {
    label: "税款申报",
    value: 0
  },
  {
    label: "零申报",
    value: 1
  },
]
const qrOptions = [
  {
    label: "自动确认",
    value: 0
  },
  {
    label: "人工确认",
    value: 1
  }
]
const ywlxOptions = [
  { label: "服务类", value: 0 },
  { label: "贸易类", value: 1 },
  { label: "生产类", value: 2 },
  { label: "工程类", value: 3 },
]
const gxOptions = [
  { label: "勾选采集", value: 0 },
  { label: "不勾选采集", value: 1 },
]
const activeName = ref('1')
const qrStatus = ref(false)
const activeTabs = ref("1")
const tempList = ref([])
onMounted(() => {
  contentStyleObj.value = proxy.$getHeight(180);
})
const dialogVisible = ref(false)
const init = (row) => {
  dialogVisible.value = true
  activeName.value = '1'
  activeTabs.value = '1'
  selectTempId.value = undefined
  reset()
  comInfo.value = row
  getInfo()
  getTemplate()
}
const getTemplate = () => {
  eaCompanyAutomaticSettingTempList({}).then(res => {
    if (res.data.msg == 'success') {
      tempList.value = res.data.data.list || []
    }
  })
}
const getInfo = () => {
  loading.value = true
  companyAutomaticSetList({ comId: comInfo.value[props.idKey] }).then(res => {
    loading.value = false
    if (res.data.msg == 'success') {
      form.value = res.data.data.list
      // form.value.myhsBlTemp = form.value.myhsBl*100
      // form.value.djblTemp = form.value.djbl*100
      // form.value.scmllTemp = form.value.scmll*100
      // form.value.zgblTemp = form.value.zgbl*100
      hintStr.value = res.data.data.str
      if (hintStr.value) {
        let str = hintStr.value.slice(-4)
        const keywordMap = {
          '服务': 0,
          '贸易': 1,
          '生产': 2,
          '工程': 3
        };
        for (const [keyword, value] of Object.entries(keywordMap)) {
          if (str.includes(keyword)) {
            form.value.ywlx = value;
            break;
          }
        }
      }
    }
  })
}
//保存
const save = () => {
  if (isNaN(Number(form.value.xjjsfz))) {
    ElMessage.warning("请输入正确的进项小额结算阀值")
    return
  }
  if (isNaN(Number(form.value.xjjsfzOut))) {
    ElMessage.warning("请输入正确的销项小额结算阀值")
    return
  }
  if (isNaN(Number(form.value.skqrfz))) {
    ElMessage.warning("请输入正确的税款阈值")
    return
  }
  if (isNaN(Number(form.value.myhsBl))) {
    ElMessage.warning("请输入正确的贸易核算比例")
    return
  }
  if (isNaN(Number(form.value.djbl))) {
    ElMessage.warning("请输入正确的单价比例")
    return
  }
  if (isNaN(Number(form.value.scmll))) {
    ElMessage.warning("请输入正确的生产比例")
    return
  }
  if (isNaN(Number(form.value.zgbl))) {
    ElMessage.warning(`请输入正确的${form.value.fyzdzg == 2 ? '利润率' : '暂估'}比例`)
    return;
  }
  // if (isNaN(Number(form.value.myhsBlTemp))) {
  //   ElMessage.warning("请输入正确的贸易核算比例")
  //   return
  // }
  // if (isNaN(Number(form.value.djblTemp))) {
  //   ElMessage.warning("请输入正确的单价比例")
  //   return
  // }
  // if (isNaN(Number(form.value.scmllTemp))) {
  //   ElMessage.warning("请输入正确的生产比例")
  //   return
  // }
  // if (isNaN(Number(form.value.zgblTemp))) {
  //   ElMessage.warning(`请输入正确的${form.value.fyzdzg == 2 ? '利润率' : '暂估'}比例`)
  //   return;
  // }
  if (isNaN(Number(form.value.salarySmall)) || isNaN(Number(form.value.salaryBig))) {
    ElMessage.warning("请输入正确的薪资比例区间")
    return
  }
  if (isNaN(Number(form.value.wpsrSmall)) || isNaN(Number(form.value.wpsrBig))) {
    ElMessage.warning("请输入正确的无票收入区间")
    return
  }
  // if (isNaN(Number(form.value.fySmall)) || isNaN(Number(form.value.fyBig))) {
  //   ElMessage.warning("请输入正确的费用区间")
  //   return
  // }
  strToNum()
  if (form.value.hslx == 1 && form.value.djblsz == 0 && form.value.djblTemp <= 0) {
    ElMessage.warning("单价成本比例设置必须大于0")
    return
  }
  let param = {
    comIds: [comInfo.value[props.idKey]],
    list: form.value,
  }
  companyAutomaticSet(param).then((res) => {
    if (res.data.msg == "success") {
      ElMessage.success("保存成功")
      closeDialogAndClearData()
      emit("success")
      getInfo()
    }
  })
}

function closeDialogAndClearData() {
  selectTempId.value = ''
  dialogVisible.value = false
}
const strToNum = () => {
  form.value.myhsBl = Number(form.value.myhsBl)
  form.value.djbl = Number(form.value.djbl)
  form.value.scmll = Number(form.value.scmll)
  form.value.zgbl = Number(form.value.zgbl)
  // form.value.myhsBl = (form.value.myhsBlTemp*1/100).toFixed(2)*1
  // form.value.djbl = (form.value.djblTemp*1/100).toFixed(2)*1
  // form.value.scmll = (form.value.scmllTemp*1/100).toFixed(2)*1
  // form.value.zgbl = (form.value.zgblTemp*1/100).toFixed(2)*1
  form.value.xjjsfz = Number(form.value.xjjsfz)
  form.value.xjjsfzOut = Number(form.value.xjjsfzOut)
  form.value.skqrfz = Number(form.value.skqrfz)
  form.value.salarySmall = Number(form.value.salarySmall)
  form.value.salaryBig = Number(form.value.salaryBig)
  form.value.wpsrSmall = Number(form.value.wpsrSmall)
  form.value.wpsrBig = Number(form.value.wpsrBig)
  form.value.fySmall = Number(form.value.fySmall)
  form.value.fyBig = Number(form.value.fyBig)
}
const changeSblx = () => {
  // if (form.value.sblx == 1) {
  //   form.value.cjqr = 0
  //   form.value.skqr = 0
  //   qrStatus.value = true
  // } else {
  //   qrStatus.value = false
  // }
}
const addTemplate = () => {
  strToNum()
  if (form.value.hslx == 1 && form.value.djblsz == 0 && form.value.djblTemp <= 0) {
    ElMessage.warning("单价成本比例设置必须大于0")
    return
  }
  ElMessageBox.prompt("请输入模板名称", "新增模板", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    inputValue: '',
    beforeClose: (action, instance, done) => {
      if (action == "confirm") {
        if (!instance.inputValue) {
          ElMessage.warning("请输入模板名称")
        } else {
          let info = Object.assign({}, form.value)
          info.name = instance.inputValue
          info.id = 0
          eaCompanyAutomaticSettingTempCre({ list: info }).then((res) => {
            done()
            if (res.data.msg == "success") {
              ElMessage.success("保存成功")
              getTemplate()
            }
          })
        }
      } else {
        done()
      }
    },
  })
}
const delTemplate = (item) => {
  ElMessageBox.confirm(`是否删除${item.name}?`, "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  }).then(() => {
    eaCompanyAutomaticSettingTempDel({ list: item }).then(res => {
      if (res.data.msg == 'success') {
        ElMessage.success("成功删除")
        getTemplate()
      }
    })
  });
}
const changeTemp = (id) => {
  const item = tempList.value.find(v => v.id == id)
  if (!item) {
    // reset()
    getInfo()
    return
  }
  form.value.sblx = item.sblx;
  form.value.gx = item.gx;
  form.value.yh = item.yh;
  form.value.cjqr = item.cjqr;
  form.value.jxjsfs = item.jxjsfs;
  form.value.xxjsfs = item.xxjsfs;
  form.value.xjjsfz = item.xjjsfz;
  form.value.xjjsfzOut = item.xjjsfzOut;
  form.value.skqr = item.skqr;
  form.value.skqrfz = item.skqrfz;
  form.value.chbc = item.chbc;
  form.value.zj_not_zero = item.zj_not_zero;
  form.value.zjqr = item.zjqr;
  form.value.hslx = item.hslx;
  form.value.zdzgsz = item.zdzgsz;
  form.value.djblsz = item.djblsz;
  form.value.myhsBl = item.myhsBl;
  form.value.djbl = item.djbl;
  form.value.zgbl = item.zgbl;
  form.value.scmll = item.scmll;
  // form.value.myhsBlTemp = item.myhsBl*100;
  // form.value.djblTemp = item.djbl*100;
  // form.value.zgblTemp = item.zgbl*100;
  // form.value.scmllTemp = item.scmll*100;
  form.value.bankGr = item.bankGr;
  form.value.chbcSpec = item.chbcSpec;
  form.value.jxyhjsfs = item.jxyhjsfs;
  form.value.xxyhjsfs = item.xxyhjsfs;
  form.value.sbshebao = item.sbshebao;
  form.value.jdcj = item.jdcj;
  form.value.jdcjBank = item.jdcjBank;
  form.value.bankqr = item.bankqr;
  form.value.shebaoqr = item.shebaoqr;
  form.value.salaryqr = item.salaryqr;
  form.value.cbjc = item.cbjc;
  form.value.salarySmall = item.salarySmall;
  form.value.salaryBig = item.salaryBig;
  form.value.wpsrStatus = item.wpsrStatus;
  form.value.wpsrZq = item.wpsrZq;
  form.value.wpsrSmall = item.wpsrSmall;
  form.value.wpsrBig = item.wpsrBig;
  form.value.fyzdzg = item.fyzdzg;
  form.value.fycb = item.fycb;
  form.value.xjjskm = item.xjjskm;
  form.value.xjjskmOut = item.xjjskmOut
  form.value.gxcj = item.gxcj
}
//成本比例自动暂估
const changeZdzg = () => {
  form.value.zdzgsz = form.value.myhsBlTemp != 0 ? 1 : 0
}
//设置行业
const setHy = () => {
  setSshyRef.value.init(comInfo.value[props.idKey])
}
const filterText = (type, flag) => {
  if (type == 2) {
    return "原材料" + (flag ? "-原材料" : "")
  } else if (type == 3) {
    return "工程物资" + (flag ? "-工程物资" : "")
  } else {
    return "库存商品" + (flag ? "-库存商品" : "")
  }
}
const reset = () => [
  form.value = {
    sblx: 0,
    ywlx: 0,
    gx: 0,
    yh: 0,
    cjqr: 0,
    jxjsfs: 0,
    xxjsfs: 0,
    xjjsfz: 0,
    xjjsfzOut: 0,
    skqr: 0,
    skqrfz: 0,
    chbc: 0,
    zj_not_zero: 0,
    zjqr: 0,
    hslx: 0,
    zdzgsz: 0,
    djblsz: 0,
    djbl: 0,
    zgbl: 0,
    scmll: 0,
    myhsBl: 0,
    // djblTemp: 0,
    // zgblTemp: 0,
    // scmllTemp: 0,
    // myhsBlTemp: 0,
    bankGr: 0,
    chbcSpec: 0,
    jxyhjsfs: 0,
    xxyhjsfs: 0,
    sbshebao: 0,
    jdcj: 0,
    jdcjBank: 0,
    bankqr: 0,
    shebaoqr: 1,
    salaryqr: 0,
    gxcj: 0
  }
]
defineExpose({ init })
</script>
<style scoped lang="scss">
.hint {
  background-image: url("../../../assets/aimodal.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-bottom: 10px;
  padding: 15px 35px;
}

.hint_title {
  font-size: 26px;
  font-weight: 700;
  background-image: -webkit-linear-gradient(left, #000, #0a5baa, #0f39a5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-bottom: 5px;
  border-bottom: 1px solid #4279b1;
  letter-spacing: 3px;
  display: inline-block;
}

.hint_content {
  display: flex;
  align-items: center;
  margin-top: 10px;
  color: #3c3c3d;

  span {
    margin-left: 5px;
  }
}

:deep(.el-alert__title) {
  letter-spacing: 2px;
}

:deep(.el-divider--horizontal) {
  margin: 0 0 15px;
}

.record {
  font-size: 14px;
  color: #333;
  margin-bottom: 3px;
  font-weight: 600;
}

.record::before {
  content: "";
  background: var(--themeColor, #17a2b8);
  display: inline-block;
  width: 3px;
  height: 13px;
  position: relative;
  margin-right: 10px;
}

.sj_style {
  width: 18px;
  height: 18px;
  margin-right: 3px;
  position: relative;
  top: 6px;
}

.el-form--inline .el-form-item {
  margin-right: 5px !important;
}

.bank_hint {
  margin-left: 10px;
}

:deep(.el-alert--success.is-light) {
  background-color: #dee9cb;
  color: #778d4f;
}

.stock {
  border: 1px solid #ededed;
  border-radius: 4px;
  margin-top: 10px;
  padding: 20px 15px;
}

.stock_hint {
  margin-top: 15px;
  color: #e6a23c;
}

.icon-wenhao {
  color: red;
  margin-left: 5px;
  font-size: 13px;
  cursor: pointer;
}

.hint_set {
  text-decoration: underline;
  cursor: pointer;
}

.hint_btn {
  margin-bottom: 10px;
}

:deep(.el-tabs--border-card>.el-tabs__content) {
  padding: 5px 10px;
}

.temp_each {
  padding: 2px 5px;
  border: 1px solid var(--themeColor, #17a2b8);
  color: var(--themeColor, #17a2b8);
  font-size: 14px;
  cursor: pointer;
  margin: 5px 0 0 5px;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
}

.temp_style {
  text-align: right;
}

.del_icon {
  margin-left: 8px;
  color: red;
  font-size: 13px;
}
</style>
<style lang="scss">
.temp_style_select_option_item .el-select-dropdown__item {
  padding: 0 20px;

  &>div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>