<template>
  <el-dialog
    :close-on-click-modal="false"
    v-model="dialogVisible"
    title="AI自动补充配置"
    width="835px"
  >
    <el-scrollbar :maxHeight="contentStyleObj" v-loading="this.loading">
      <div v-if="type" style="color: red; margin-bottom: 10px">
        <i
          class="iconfont icon-sanjiaotanhao"
          style="color: #e6a23c; margin-right: 5px"
        ></i
        >注意：不勾选默认全部自动补充，自动补充会同时清除关联凭证信息
      </div>
      <el-form :model="form" label-width="140px" size="small" :inline="true">
        <div class="record" v-if="type != 'bank'">基本设置</div>
        <el-form-item label="申报类型:" v-if="!type">
          <el-radio-group v-model="form.sblx" @change="changeSblx">
            <el-radio :label="0">税款申报</el-radio>
            <el-radio :label="1"
              >零申报
              <el-tooltip content="不进行发票采集和银行采集" placement="top">
                <i
                  class="iconfont icon-wenhao"
                  style="color: red; font-size: 13px; cursor: pointer"
                ></i> </el-tooltip
            ></el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="一般纳税人是否勾选:" v-if="!type">
          <el-radio-group v-model="form.gx" disabled>
            <el-radio :label="0">是</el-radio>
            <el-radio :label="1">否</el-radio>
          </el-radio-group>
          <el-tooltip content="（默认全部勾选确认）" placement="top">
            <i
              class="iconfont icon-wenhao"
              style="
                color: red;
                margin-left: 5px;
                font-size: 13px;
                cursor: pointer;
              "
            ></i>
          </el-tooltip>
        </el-form-item>
        <el-form-item label="公司业务类型:" v-if="type != 'bank'">
          <el-radio-group v-model="form.ywlx" @change="changeZdzg">
            <el-radio :label="0">服务类</el-radio>
            <el-radio :label="1">贸易类</el-radio>
            <el-radio :label="2">生产类</el-radio>
            <el-radio :label="3">工程类</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="特殊行业:" v-if="type != 'bank'">
          <el-radio-group v-model="form.tshy">
            <el-select
              v-model="form.tshy"
              style="width: 154px; margin: 0"
              clearable
            >
              <el-option label="成品油" value="成品油"></el-option>
              <el-option label="办公用品销售" value="办公用品销售"></el-option>
              <el-option label="水电销售" value="水电销售"></el-option>
              <el-option label="天然气销售" value="天然气销售"></el-option>
            </el-select>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="资金赤字自动调整:" v-if="!type">
          <el-radio-group v-model="form.zj_not_zero">
            <el-radio :label="0">是</el-radio>
            <el-radio :label="1">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <div v-if="!type">
          <el-form-item label="是否申报社保:">
            <el-radio-group v-model="form.sbshebao">
              <el-radio :label="0">否</el-radio>
              <el-radio :label="1">是</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="质检是否自动确认:" label-width="198px">
            <el-radio-group v-model="form.zjqr">
              <el-radio :label="0">是</el-radio>
              <el-radio :label="1">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <div class="record">{{ type == "bank" ? "银行补充" : "发票补充" }}</div>
        <el-form-item label="小规模季度是否采集:" v-if="!type">
          <el-radio-group v-model="form.jdcj">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>

        <div v-if="form.sblx == 0">
          <el-form-item
            label="单据是否自动确认:"
            style="width: 650px"
            v-if="!type"
          >
            <el-radio-group v-model="form.cjqr" :disabled="qrStatus">
              <el-radio :label="0">是</el-radio>
              <el-radio :label="1">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="type != 'bank'" label="进项结算方式">
            <el-select v-model="form.jxjsfs" style="width: 154px; margin: 0">
              <el-option label="应付账款" :value="0"></el-option>
              <el-option label="预付账款" :value="1"></el-option>
              <el-option label="其他应付款" :value="2"></el-option>
              <el-option label="现金结算" :value="3"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="type != 'bank'" label="销项结算方式">
            <el-select v-model="form.xxjsfs" style="width: 154px; margin: 0">
              <el-option label="应收账款" :value="0"></el-option>
              <el-option label="预收账款" :value="1"></el-option>
              <el-option label="其他应收款" :value="2"></el-option>
              <el-option label="现金结算" :value="3"></el-option>
            </el-select>
          </el-form-item>

          <div v-if="type != 'bank'">
            <el-form-item label="进项小额结算阈值:">
              <el-input v-model="form.xjjsfz" style="width: 154px"></el-input>
            </el-form-item>
            <el-form-item label="进项小额结算科目:">
              <el-select v-model="form.xjjskm" style="width: 154px; margin: 0">
                <el-option label="现金" :value="0"></el-option>
                <el-option label="其他应付款-小额支出" :value="1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="销项小额结算阈值:">
              <el-input
                v-model="form.xjjsfzOut"
                style="width: 154px"
              ></el-input>
            </el-form-item>
            <el-form-item label="销项小额结算科目:">
              <el-select
                v-model="form.xjjskmOut"
                style="width: 154px; margin: 0"
              >
                <el-option label="现金" :value="0"></el-option>
                <el-option label="其他应收款-小额收入" :value="1"></el-option>
              </el-select>
            </el-form-item>
          </div>

          <el-form-item
            label="存货补充:"
            v-if="
              (form.ywlx == 1 || form.ywlx == 2 || form.ywlx == 3) &&
              type != 'bank'
            "
          >
            <el-radio-group v-model="form.chbc">
              <el-radio :label="0"
                >{{
                  form.ywlx == 2
                    ? "原材料"
                    : form.ywlx == 3
                    ? "工程物资"
                    : "库存商品"
                }}-商品分类<span style="color: red">（推荐）</span></el-radio
              >
              <el-radio
                style="display: block"
                :label="1"
                v-if="this.checkDisabled"
                >{{
                  form.ywlx == 2
                    ? "原材料"
                    : form.ywlx == 3
                    ? "工程物资"
                    : "库存商品"
                }}-商品名称</el-radio
              >
              <el-radio
                style="display: block"
                :label="2"
                v-if="this.checkDisabled"
                >{{
                  form.ywlx == 2
                    ? "原材料"
                    : form.ywlx == 3
                    ? "工程物资"
                    : "库存商品"
                }}-商品分类-商品名称 （复杂）</el-radio
              >
              <el-radio
                style="display: block"
                :label="3"
                v-if="this.checkDisabled"
                >{{
                  form.ywlx == 2
                    ? "原材料-原材料"
                    : form.ywlx == 3
                    ? "工程物资-工程物资"
                    : "库存商品-库存商品"
                }}（简单）</el-radio
              >
              <el-radio
                style="display: block"
                :label="4"
                v-if="this.checkDisabled"
                >{{
                  form.ywlx == 2
                    ? "原材料"
                    : form.ywlx == 3
                    ? "工程物资"
                    : "库存商品"
                }}-商品分类-商品名称（辅助核算）</el-radio
              >
              <el-radio
                style="display: block"
                :label="5"
                v-if="this.checkDisabled"
                >{{
                  form.ywlx == 2
                    ? "原材料"
                    : form.ywlx == 3
                    ? "工程物资"
                    : "库存商品"
                }}-商品名称（辅助核算）</el-radio
              >
              <el-radio
                style="display: block"
                :label="6"
                v-if="this.checkDisabled"
                >{{
                  form.ywlx == 2
                    ? "原材料"
                    : form.ywlx == 3
                    ? "工程物资"
                    : "库存商品"
                }}（极简）</el-radio
              >
              <span
                v-if="this.checkDisabled"
                style="
                  text-decoration: underline;
                  cursor: pointer;
                  font-size: 12px;
                  color: var(--themeColor, #17a2b8);
                  margin-top: 2px;
                "
                @click="elCheck"
                >简易设置</span
              >
              <span
                v-if="!this.checkDisabled"
                style="
                  text-decoration: underline;
                  cursor: pointer;
                  font-size: 12px;
                  color: var(--themeColor, #17a2b8);
                "
                @click="elCheck"
                >高级设置</span
              >
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="存货是否使用型号:"
            style="width: 650px"
            v-if="[1, 2, 4, 5].includes(form.chbc)"
          >
            <el-radio-group v-model="form.chbcSpec">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <div v-else class="skip">跳过单据采集</div>
        <div class="record" v-if="!type">银行补充</div>
        <div v-if="form.sblx == 0">
          <div>
            <el-form-item label="小规模季度是否采集:" v-if="!type">
              <el-radio-group v-model="form.jdcjBank">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
          <el-form-item label="银行是否采集:" v-if="!type">
            <el-radio-group v-model="form.yh">
              <el-radio :label="0">是</el-radio>
              <el-radio :label="1">否</el-radio>
            </el-radio-group>
          </el-form-item>

          <div>
            <el-form-item v-if="type == 'bank' || !type" label="进项对冲科目">
              <el-select
                v-model="form.jxyhjsfs"
                style="width: 154px; margin: 0"
              >
                <el-option label="应付账款" :value="0"></el-option>
                <el-option label="预付账款" :value="1"></el-option>
                <el-option label="其他应付款" :value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-if="type == 'bank' || !type" label="销项对冲科目">
              <el-select
                v-model="form.xxyhjsfs"
                style="width: 154px; margin: 0"
              >
                <el-option label="应收账款" :value="0"></el-option>
                <el-option label="预收账款" :value="1"></el-option>
                <el-option label="其他应收款" :value="2"></el-option>
              </el-select>
            </el-form-item>
          </div>

          <el-form-item
            label="个人往来结算科目:"
            v-if="type == 'bank' || !type"
          >
            <el-radio-group v-model="form.bankGr">
              <el-radio :label="0">其他应收/应付账款_具体人员名称</el-radio>
              <el-radio :label="1">其他应收/应付账款_个人</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <div v-else class="skip">跳过银行采集</div>
        <div v-if="!type">
          <div class="record">成本核算</div>
          <div v-if="form.sblx == 0">
            <div>
              <el-form-item
                label="生产成本毛利率(小数):"
                v-if="form.ywlx == 2"
                label-width="145px"
              >
                <el-input v-model="form.scmll" style="width: 154px"></el-input>
                <span
                  style="margin-left: 5px; color: var(--themeColor, #17a2b8)"
                  >*0默认不核算</span
                >
              </el-form-item>
            </div>

            <el-form-item
              :label="
                form.ywlx == 1 || form.ywlx == 2
                  ? '贸易核算类型'
                  : '工程核算类型'
              "
              v-if="form.ywlx != 0"
            >
              <el-radio-group v-model="form.hslx">
                <el-radio :label="0">毛利率核算</el-radio>
                <el-radio :label="1" v-if="form.ywlx == 1 || form.ywlx == 2"
                  >进销核算</el-radio
                >
                <!-- <el-radio :label="2" disabled>工程类核算</el-radio> -->
              </el-radio-group>
            </el-form-item>
            <el-form-item label="毛利率比例(小数):" v-if="form.hslx == 0">
              <el-input
                v-model="form.myhsBl"
                style="width: 154px"
                @change="changeZdzg"
              ></el-input>
              <span style="margin-left: 5px; color: var(--themeColor, #17a2b8)"
                >*0默认不核算</span
              >
            </el-form-item>
            <div v-if="(form.ywlx == 1 || form.ywlx == 2) && form.hslx == 1">
              <el-form-item label="单价比例自动设置:">
                <el-radio-group v-model="form.djblsz">
                  <el-radio :label="0">是</el-radio>
                  <el-radio :label="1">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="单价成本比例设置(小数):" label-width="194px">
                <el-input v-model="form.djbl" style="width: 154px"> </el-input>
              </el-form-item>

              <!-- <el-form-item label="暂估比例(小数):" label-width="194px">
                <el-input v-model="form.zgbl" style="width:154px"> </el-input>
              </el-form-item> -->
            </div>
            <el-form-item
              :label="
                this.form.ywlx == 0
                  ? '费用自动暂估'
                  : this.form.ywlx == 1
                  ? '存货自动暂估'
                  : '存货自动暂估'
              "
            >
              <el-radio-group v-model="form.zdzgsz">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
          <div v-else class="skip">无需成本核算</div>
        </div>
        <div v-if="!type">
          <div class="record">申报设置</div>
          <el-form-item label="税款是否自动确认:">
            <el-radio-group v-model="form.skqr" :disabled="form.sblx">
              <el-radio :label="0">是</el-radio>
              <el-radio :label="1">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="自动税款确认限额:">
            <el-input v-model="form.skqrfz" style="width: 154px"></el-input
            >（元）
            <el-tooltip
              content="（*单税款金额低于此金额，系统自动确认。）"
              placement="top"
            >
              <i
                class="iconfont icon-wenhao"
                style="
                  color: red;
                  margin-left: 5px;
                  font-size: 13px;
                  cursor: pointer;
                "
              ></i>
            </el-tooltip>
          </el-form-item>
        </div>
      </el-form>
    </el-scrollbar>

    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取消</el-button>
        <el-button size="small" type="primary" @click="save">确定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { companyAutomaticSetList, companyAutomaticSet } from "@/api/automatic";
import { autoInInvoice, autoOutInvoice } from "@/api/invoice";
import { autoBank } from "@/api/bank";
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      dialogVisible: false,
      contentStyleObj: {},
      comId: null,
      form: {
        sblx: 0,
        ywlx: 0,
        gx: 0,
        yh: 0,
        cjqr: 0,
        jxjsfs: 0,
        xxjsfs: 0,
        xjjsfz: 0,
        xjjsfzOut: 0,
        skqr: 0,
        skqrfz: 0,
        chbc: 0,
        myhsBl: 0,
        zj_not_zero: 0,
        zjqr: 0,
        hslx: 0,
        zdzgsz: 0,
        djblsz: 0,
        djbl: 0,
        zgbl: 0,
        tshy: "",
        bankGr: 0,
        scmll: 0,
        chbcSpec: 0,
        jxyhjsfs: 0,
        xxyhjsfs: 0,
        sbshebao: 0,
        jdcj: 0,
        jdcjBank: 0,
      },
      comIds: [],
      loading: false,
      qrStatus: false,
      checkDisabled: false,
      type: "",
      bcIds: [],
    };
  },
  mounted() {
    this.contentStyleObj = this.$getHeight(250);
  },
  methods: {
    init(ids, type, arr) {
      this.dialogVisible = true;
      this.type = type ? type : "";
      this.bcIds = arr ? arr : [];
      this.reset();
      this.comIds = ids;
      if (ids.length == 1) {
        this.comId = ids[0];
        this.getInfo();
      }
    },
    getInfo() {
      this.loading = true;
      companyAutomaticSetList({ comId: this.comId }).then((res) => {
        this.loading = false;
        if (res.data.msg == "success" && res.data.data.list.id) {
          this.form = res.data.data.list;
          if (this.form.chbc == 0) {
            this.checkDisabled = false;
          } else {
            this.checkDisabled = true;
          }
        }
      });
    },
    reset() {
      this.form = {
        sblx: 0,
        ywlx: 0,
        gx: 0,
        yh: 0,
        cjqr: 0,
        jxjsfs: 0,
        xxjsfs: 0,
        xjjsfz: 0,
        xjjsfzOut: 0,
        skqr: 0,
        skqrfz: 0,
        chbc: 0,
        myhsBl: 0,
        zj_not_zero: 0,
        zjqr: 0,
        hslx: 0,
        zdzgsz: 0,
        djblsz: 0,
        djbl: 0,
        zgbl: 0,
        bankGr: 0,
        scmll: 0,
        chbcSpec: 0,
        jxyhjsfs: 0,
        xxyhjsfs: 0,
        sbshebao: 0,
        jdcj: 0,
        jdcjBank: 0,
      };
    },
    elCheck() {
      this.checkDisabled = !this.checkDisabled;
    },
    save() {
      if (isNaN(Number(this.form.xjjsfz))) {
        this.$qzfMessage("请输入正确的进项小额结算阀值", 1);
        return;
      }
      if (isNaN(Number(this.form.xjjsfzOut))) {
        this.$qzfMessage("请输入正确的销项小额结算阀值", 1);
        return;
      }
      if (isNaN(Number(this.form.skqrfz))) {
        this.$qzfMessage("请输入正确的税款阈值", 1);
        return;
      }
      if (isNaN(Number(this.form.myhsBl))) {
        this.$qzfMessage("请输入正确的贸易核算比例", 1);
        return;
      }
      // if (isNaN(Number(this.form.zgbl))) {
      //   this.$qzfMessage("请输入正确的暂估比例", 1);
      //   return;
      // }
      if (isNaN(Number(this.form.djbl))) {
        this.$qzfMessage("请输入正确的单价比例", 1);
        return;
      }
      if (isNaN(Number(this.form.scmll))) {
        this.$qzfMessage("请输入正确的生产比例", 1);
        return;
      }
      this.form.myhsBl = Number(this.form.myhsBl);
      this.form.xjjsfz = Number(this.form.xjjsfz);
      this.form.xjjsfzOut = Number(this.form.xjjsfzOut);
      this.form.skqrfz = Number(this.form.skqrfz);
      this.form.djbl = Number(this.form.djbl);
      this.form.scmll = Number(this.form.scmll);
      // this.form.zgbl = Number(this.form.zgbl);
      if (this.type == "in") {
        this.dialogVisible = false;
        this.$emit("success2");
        let param = {
          setting: this.form,
          ids: this.bcIds,
        };
        autoInInvoice(param).then((res) => {
          // 发起超过一定量时间就报跟发票类似的
          if (res.data.msg == "success") {
            if (res.data.data.msg != "") {
              this.$qzfMessage(res.data.data.msg);
            } else {
              this.$qzfMessage("进项补充成功");
            }
          }
          this.$bus.emit("autoBcStatusUpdate");
          this.$emit("success");
        });
      } else if (this.type == "out") {
        this.dialogVisible = false;
        this.$emit("success2");
        let param = {
          setting: this.form,
          ids: this.bcIds,
        };
        autoOutInvoice(param).then((res) => {
          if (res.data.msg == "success") {
            if (res.data.data.msg != "") {
              this.$qzfMessage(res.data.data.msg);
            } else {
              this.$qzfMessage("销项补充成功");
            }
          }
          this.$bus.emit("autoBcStatusUpdate");
          this.$emit("success");
        });
      } else if (this.type == "bank") {
        this.dialogVisible = false;
        this.$emit("success2");
        let param = {
          setting: this.form,
          ids: this.bcIds,
        };
        autoBank(param).then((res) => {
          if (res.data.msg == "success") {
            if (res.data.data.msg != "") {
              this.$qzfMessage(res.data.data.msg);
            } else {
              this.$qzfMessage("银行补充成功");
            }
          }
          this.$bus.emit("autoBcStatusUpdate");
          this.$emit("success");
        });
      } else {
        let param = {
          comIds: this.comIds,
          list: this.form,
        };
        companyAutomaticSet(param).then((res) => {
          if (res.data.msg == "success") {
            this.$qzfMessage("保存成功");
            this.dialogVisible = false;
            this.$emit("success");
          }
        });
      }
    },
    changeSblx() {
      if (this.form.sblx == 1) {
        this.form.cjqr = 0;
        this.form.skqr = 0;
        this.qrStatus = true;
      } else {
        this.qrStatus = false;
      }
    },
    changeZdzg() {
      if (this.form.myhsBl != 0) {
        this.form.zdzgsz = 1;
      } else {
        this.form.zdzgsz = 0;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.record {
  font-size: 14px;
  color: #333;
  font-weight: 600;
  margin-bottom: 10px;
}
.record::before {
  content: "";
  background: var(--themeColor, #17a2b8);
  display: inline-block;
  width: 3px;
  height: 13px;
  position: relative;
  margin-right: 10px;
}
.skip {
  color: var(--themeColor, #17a2b8);
  margin: 15px 0;
  font-size: 14px;
  margin-left: 45px;
}
</style>
